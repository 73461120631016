html,
body {
  height: 100%;
  overflow: hidden;
}
.layout {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
}

.bi {
  display: inline-block;
  vertical-align: -0.125rem;
  width: 1em;
  height: 1em;
}
.nav-main {
  background-color: #f8f8ff;
  background-color: #f6f6fe;
}
.nav-pills {
  border-radius: var(--bs-border-radius);
}
.nav-logo {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
}
.nav-profile {
  display: flex;
  align-items: center;
  line-height: 1.2;
}

.tool-titlebar {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.tools-list {
  width: 100%;
  max-width: 50rem;
  margin: auto;
  padding: 2rem 1rem 5rem 1rem;
}
.tool-chat {
  justify-content: flex-end;
}
.tool-chat-messages {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  :first-child {
    margin-top: auto;
  }
}
.tool-chat-form {
  flex: 0 0 auto;
}
.tool-chat-messages-parent {
  height: 100%;
  display: flex;
  padding-top: 0.25rem;
}
[class*="tool-chat-message-"] {
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}
.message {
  justify-self: start;
  margin-top: initial !important;
}
.error {
  color: red;
}
.tool-footer {
  border-top: 1px solid #ddd;
  position: sticky;
  bottom: 0;
  background-color: #f6f6fe;
}

.card-ui-element {
  background-color: #eee;
  margin-top: -1px;
  border-radius: 0;
}
.card-ui-element:first-child {
  border-radius: var(--bs-border-radius) var(--bs-border-radius) 0 0;
}
.card-ui-element:last-child {
  border-radius: 0 0 var(--bs-border-radius) var(--bs-border-radius);
}

.public-DraftEditorPlaceholder-inner {
  display: none;
}

//Copy pasted from bootstrap .form-control because draftjs does react-highlight-within-textarea or draftjs does not allow adding className.
.public-DraftEditor-content {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
//Copy pasted from bootstrap .form-control because draftjs does react-highlight-within-textarea or draftjs does not allow adding className.
.public-DraftEditor-content:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.public-DraftEditor-content {
  min-height: 6rem;
}

.bg-chat {
  background-color: #f6f6f7;
}

.bg-chat-dark {
  background-color: #090908;
}

.bg-dark {
  background-color: #2f2f34;
}

.bouncing-loader {
  display: inline-block;
  clip-path: inset(0 0.7ch 0 0);
  animation: l 2s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}

.min-footer {
  min-height: 6rem;
}

.h-90 {
  height: 100%;
}

.h-max-chat {
  max-height: 75%;
}

.h-with-header {
  max-height: 75%;
}

.h-without-header {
  max-height: 85%;
}

.min-chat-input {
  min-height: 2.5rem;
}

.dot-flashing {
  position: relative;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.25rem;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -0.75rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.25rem;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 0.75rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.25rem;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

.h-90-percent {
  height: 90%;
}

.dissapearing {
  animation: fadeOut 10s linear 0s infinite;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.chat-scroll > :first-child {
  margin-top: auto;
}

.bottom-chat-input {
  bottom: 4.2rem;
}

.background-modal {
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
}
